import React, { useEffect, useState, useRef } from "react"
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DebounceInput } from 'react-debounce-input'
import Select from 'react-select'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import TableControl from 'pages/parts/TableControl'

import { PGET, GET, POST, PUT, DELETE } from 'helpers/api'
import { formatDate, formatMoney } from 'helpers/helpers'
import ConfirmModal from "pages/parts/ConfirmModal"

function CreateUpdate() {
	const { t } = useTranslation()
	const { id } = useParams();

	const navigate = useNavigate();

	const dispatch = useDispatch()

	const reduxSettings = useSelector(state => state.settings)
	const reduxTransferData = useSelector(state => state.transferData)

	const searchRef = useRef(null);

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const [poses, setPoses] = useState([])
	const [poses2, setPoses2] = useState([])
	const [searchData, setSearchData] = useState([])
	const [search, setSearch] = useState('')
	const [searchDataExist, setSearchDataExist] = useState(0)
	const [barcode, setBarcode] = useState(false)
	const [modalConfirm, setModalConfirm] = useState(false)
	const [modalDeleteConfirm, setModalDeleteConfirm] = useState({
		show: false,
		index: 0
	})
	const [modalTableControl, setModalTableControl] = useState(false)
	const [data, setData] = useState({
		productList: [],
		fromPosId: null,
		toPosId: null,
		totalAmount: 0,
		error: '',
	})

	async function saveComplete(completed = false) {
		var sendData = { ...data }

		for (let i = 0; i < sendData.productList.length; i++) {
			sendData.productList[i]['totalAmount'] = sendData.productList[i]['price'] * sendData.productList[i]['quantity']

			if (!sendData.productList[i]['quantity']) {
				console.log(sendData.productList[i]['quantity']);
				sendData.error = ': ' + t('QUANTITY')
			}
			if (sendData.productList[i]['price'] === "" || sendData.productList[i]['price'] < 0.0001) {
				console.log('price', sendData.productList[i]['price']);
				sendData.error = ': ' + t('PRICE')
			}
			// if (sendData.productList.findIndex(item => item.productId === sendData.productList[i].productId) >= 0) {
			// 	toast.error(t('IDENTICAL_PRODUCTS_SELECTED'))
			// 	sendData.error = true;
			// 	return
			// }
			// if (sendData.productList[i]['wholesalePrice'] === "" || (Number(sendData.productList[i]['wholesalePrice']) < Number(sendData.productList[i]['price']))) {
			// 	console.log('wholesalePrice', sendData.productList[i]['wholesalePrice']);
			// 	sendData.error = true
			// }
			// if (sendData.productList[i]['wholesalePrice'] === "" || (Number(sendData.productList[i]['salePrice']) < Number(sendData.productList[i]['wholesalePrice']))) {
			// 	console.log('salePrice', sendData.productList[i]['salePrice']);
			// 	sendData.error = true
			// }
			if (Number(sendData.productList[i]['fromCurrencyIdCopy']) !== Number(sendData.productList[i]['toCurrencyId']) && !id) {
				sendData.error = true
			}
		}

		if (sendData.error) {
			toast.error(t('ERROR') + ': ' + t('CHECK_FILLED_INPUTS') + sendData.error)
			return
		}
		dispatch({ type: 'SHOW_LOADER' })
		var response;
		if (id && !completed) {
			response = await PUT('/services/web/api/transfer', sendData, { loader: false })
			navigate('/transfer')
		}

		if (id && completed) {
			sendData.completed = true
			response = await PUT('/services/web/api/transfer-send', sendData, { loader: false })
			if (response && response?.success) {
				navigate('/transfer')
			}
		}

		if (!id) {
			response = await POST('/services/web/api/transfer', sendData, { loader: false })
			if (response) {
				const temporaryData = await GET('/services/web/api/transfer/' + response.data.id)
				if (completed) {
					temporaryData.completed = true
					await PUT('/services/web/api/transfer-send', temporaryData, { loader: false })
				}
				dispatch({ type: 'SET_TRANSFER_CREATE_DATA', payload: {} })
				navigate('/transfer')
			}
		}
		dispatch({ type: 'HIDE_LOADER' })
	}

	function handleProductListChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.productList[index][e.target.name] = e.target.value

		if (e.target.name === 'quantity') {
			var temporaryTotalAmount = 0

			for (let i = 0; i < dataCopy.productList.length; i++) {
				if (dataCopy.productList[i]['price'] && dataCopy.productList[i]['quantity']) {
					temporaryTotalAmount += data.productList[i]['price'] * data.productList[i]['quantity']
					dataCopy.totalAmount = temporaryTotalAmount
				} else {
					dataCopy.totalAmount = 0
				}
			}
		}

		setData(dataCopy)
	}

	async function handlePosSelect(option) {
		setData({ ...data, 'fromPosId': option.id, 'toPosId': null })

		const response = await GET(`/services/web/api/pos-transfer-helper/${option.id}`, {}, { loader: true })
		setPoses2(response)
	}

	async function searchProduct(value) {
		setSearchDataExist(0)
		setSearch(value)
		if (value.length === 0) {
			setSearchData([])
			setSearchDataExist(0)
		} else {
			const response = await PGET("/services/web/api/product-transfer-list",
				{ 'name': value, 'fromPosId': data.fromPosId, 'toPosId': data.toPosId, 'barcode': barcode },
				{ loader: true })

			var dataCopy = { ...data }
			for (let i = 0; i < response.data.length; i++) {
				for (let j = 0; j < dataCopy.productList.length; j++) {
					if (
						response.data[i]['productId'] === dataCopy.productList[j]['productId'] &&
						response.data[i]['fromCurrencyId'] === dataCopy.productList[j]['fromCurrencyId'] &&
						response.data[i]['toCurrencyId'] === dataCopy.productList[j]['toCurrencyId']
					) {
						if (response.data.length === 1) {
							setSearchDataExist(2)
							setSearch('')
						}
						response.data.splice(i, 1)
					}
				}
			}

			if (response.data.length === 0) {
				setSearchDataExist(1)
				return
			}

			if (/^\d+$/.test(value) && response.data.length === 1) {
				setSearchData(response.data)
				addToList(0, response.data)
				setSearch('')
			} else {
				setSearchData(response.data)
			}
		}
	}

	function addToList(index, arr = []) {
		var searchDataCopy;
		if (arr.length > 0) {
			searchDataCopy = arr
		} else {
			searchDataCopy = [...searchData]
		}
		var dataCopy = { ...data }

		var found = dataCopy.productList.find(item => item.productBarcode === searchDataCopy[index]['productBarcode']);
		if (found?.id) {
			toast.error(t('PRODUCT_ADDED'))
			return
		}

		searchDataCopy[index].fromCurrencyIdCopy = searchDataCopy[index]['fromCurrencyId']
		searchDataCopy[index].currencyId = searchDataCopy[index]['toCurrencyId']
		searchDataCopy[index].quantity = ''
		dataCopy.productList.push(searchDataCopy[index])
		setData(dataCopy)

		searchDataCopy.splice(index, 1)
		setSearchData(searchDataCopy)

		if (searchDataCopy.length === 0) {
			setSearch('')
		}

		searchRef.current.focus()
	}

	function hideModalDeleteConfirm() {
		setModalDeleteConfirm({ show: false })
	}

	async function deleteProduct() {
		var dataCopy = { ...data }
		var item = dataCopy.productList[modalDeleteConfirm.index]

		if (item['id']) {
			await DELETE(`/services/web/api/transfer-product/${item['id']}/${id}`, {}, { loader: true })
		}
		dataCopy.productList.splice(modalDeleteConfirm.index, 1)
		setData(dataCopy)
		setModalDeleteConfirm({ show: false })
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}

		if (id) {
			const temporaryData = await GET('/services/web/api/transfer/' + id)

			for (let i = 0; i < temporaryData.length; i++) {
				temporaryData[i].fromCurrencyIdCopy = temporaryData[i]['fromCurrencyId']
				temporaryData[i].currencyId = temporaryData[i]['toCurrencyId']
			}

			setData(temporaryData)
			setPoses2(response)
		} else {
			if (reduxTransferData.transferCreateData?.productList && reduxTransferData.transferCreateData?.productList?.length) {
				console.log(reduxTransferData.transferCreateData);
				setData(reduxTransferData.transferCreateData)
				const response = await GET(`/services/web/api/pos-transfer-helper/${reduxTransferData.transferCreateData.fromPosId}`, {}, { loader: true })
				setPoses2(response)
			}
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (reduxTransferData?.transferCreateData?.productList?.length > 0 && data.productList.length === 0) {
			return;
		}
		if (id) {
			return;
		}
		dispatch({ type: 'SET_TRANSFER_CREATE_DATA', payload: data })
	}, [data]) // eslint-disable-line react-hooks/exhaustive-deps	

	return (
		<>
			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('SENDER_POS')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={id || data.productList.length > 0}
										options={poses}
										value={poses.find(option => option.id === data.fromPosId)}
										onChange={(option) => handlePosSelect(option)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('RECIPIENT_POS')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={id || data.productList.length > 0}
										options={poses2}
										value={poses2.find(option => option.id === data.toPosId)}
										onChange={(option) => setData({ ...data, 'toPosId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<div className="table-responsive mb-3">
							<table className="table table-striped mb-0">
								<thead>
									<tr>
										<th>{t('NAME_OF_PRODUCT')}</th>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.categoryName) &&
											<th>{t('CATEGORY')}</th>
										}
										<th className="text-center">{t('BARCODE')}</th>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.artikul) &&
											<th className="text-center">{t('ARTIKUL')}</th>
										}
										<th className="text-center">{t('UNIT')}.</th>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.serial) &&
											<th className="text-center">{t('SERIAL')}</th>
										}
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.expDate) &&
											<th className="text-center">{t('EXPIRATION_DATE')}</th>
										}
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.vat) &&
											<th className="text-center">{t('VAT')}</th>
										}
										<th className="text-center">{t("PRICE")}</th>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.wholesalePrice) &&
											<th className="text-center">{t('WHOLESALE_PRICE')}</th>
										}
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.bankPrice) &&
											<th className="text-center">{t('BANK_PRICE')}</th>
										}
										<th className="text-center">{t('UNIT_PRICE')}</th>
										<th className="text-center">{t('RESIDUE')}</th>
										<th className="text-center">{t('QUANTITY')}</th>
										<th className="text-center">{t('CURRENCY')}</th>
										<th className="text-center">{t('CURRENCY')}</th>
										<th className="text-center table-control">
											<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
											{t('ACTION')}
										</th>
									</tr>
								</thead>
								<tbody>
									{data.productList?.map((item, index) => (
										<tr key={index}>
											<td>{index + 1}. {item.productName}</td>
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.categoryName) &&
												<td>{item.categoryName}</td>
											}
											<td className="text-center">{item.productBarcode}</td>
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.artikul) &&
												<td className="text-center">{item.productArtikul}</td>
											}
											<td className="text-center">{item.uomName}</td>
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.serial) &&
												<td className="text-center">{item.serial}</td>
											}
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.expDate) &&
												<td className="text-center">{formatDate(item.expDate)}</td>
											}
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.vat) &&
												<td className="text-center">{item.vat}</td>
											}
											<td className="text-center">
												{(item.fromCurrencyIdCopy === item.toCurrencyId) ?
													<span>{item.price}</span>
													:
													<input type="number" className={"auto-width-input " + (!item.price && "required-border")}
														name="price" value={item.price}
														onChange={(e) => handleProductListChange(e, index)}
													/>
												}
											</td>
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.wholesalePrice) &&
												<td className="text-center">
													{(item.fromCurrencyIdCopy === item.toCurrencyId) ?
														<span>{item.wholesalePrice}</span>
														:
														<input type="number" className={"auto-width-input " + (!item.wholesalePrice && "required-border")}
															name="wholesalePrice" value={item.wholesalePrice}
															onChange={(e) => handleProductListChange(e, index)}
														/>
													}
												</td>
											}
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.bankPrice) &&
												<td className="text-center">
													{(item.fromCurrencyIdCopy === item.toCurrencyId) ?
														<span>{item.bankPrice}</span>
														:
														<input type="number" className={"auto-width-input " + (!item.bankPrice && "required-border")}
															name="bankPrice" value={item.bankPrice}
															onChange={(e) => handleProductListChange(e, index)}
														/>
													}
												</td>
											}
											<td className="text-center">
												{(item.fromCurrencyIdCopy === item.toCurrencyId) ?
													<span>{item.salePrice}</span>
													:
													<input type="number" className={"auto-width-input " + (!item.salePrice && "required-border")}
														name="salePrice" value={item.salePrice}
														onChange={(e) => handleProductListChange(e, index)}
													/>
												}
											</td>
											<td className="text-center">
												<span>{item.fromPosBalance}</span>
											</td>
											<td className="text-center">
												<input type="number" className={"auto-width-input " + (!item.quantity && "required-border")}
													name="quantity" value={item.quantity}
													disabled={data.send}
													onChange={(e) => {
														if (e.target.value <= item.fromPosBalance) {
															handleProductListChange(e, index)
														}
													}} />
											</td>
											<td className="text-center">
												<select className="form-select" style={{ 'padding': '0rem 1.75rem 0rem 0.75rem' }}
													disabled={(item.fromCurrencyIdCopy === item.toCurrencyId)}
													name="fromCurrencyIdCopy"
													value={item.fromCurrencyIdCopy}
													onChange={(e) => handleProductListChange(e, index)}>
													{currencies.map((item, index) => (
														<option value={item.id} key={index}>{item.name}</option>
													))
													}
												</select>
											</td>
											<td className="text-center">
												<select className="form-select" style={{ 'padding': '0rem 1.75rem 0rem 0.75rem' }}
													disabled
													defaultValue={item.toCurrencyId}>
													{currencies.map((item, index) => (
														<option value={item.id} key={index}>{item.name}</option>
													))
													}
												</select>
											</td>
											<td>
												{
													<div className="d-flex justify-content-center" onClick={() => setModalDeleteConfirm({ show: true, index: index })}>
														{!data.send && <div className="table-action-button table-action-danger-button">
															<i className="uil uil-times"></i>
														</div>
														}
													</div>}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>

						<div className="row">
							<div className="col-md-6 vertical-center">
								<div className="form-group position-relative m-0">
									{!data.send &&
										<><DebounceInput
											inputRef={searchRef}
											disabled={(!data.fromPosId || !data.toPosId)}
											value={search}
											onChange={(e) => searchProduct(e.target.value)}
											onKeyUp={(e) => {
												if (e.keyCode === 13) {
													searchProduct(e.target.value)
												}
											}}
											type="text"
											className="form-control"
											placeholder={t('SEARCH') + '...'}
											debounceTimeout={1000}
										/>
											{searchData.length > 0 ?
												<i className="uil uil-times search-barcode" onClick={() => { setSearchData([]); setSearch('') }}></i>
												:
												<i className={'uil uil-qrcode-scan search-barcode ' + (barcode && 'text-primary')} onClick={() => setBarcode(!barcode)}></i>
											}
										</>
									}
									{searchData.length > 0 &&
										<span className="dropdown-search-menu">
											<div className="dropdown-menu-list">
												<span className="dropdown-menu-result">
													<div className="table-responsive p-3">
														<table className="table-striped table">
															<thead>
																<tr>
																	<th>{t('NAME_OF_PRODUCT')}</th>
																	<th className="text-center">{t('BARCODE')}</th>
																	<th className="text-center">{t('PRICE')}</th>
																	<th className="text-center">{t('RESIDUE')}</th>
																	<th className="text-center">{t('ACTION')}</th>
																</tr>
															</thead>
															<tbody>
																{searchData.map((item, index) => (
																	<tr key={index}>
																		<td>{item.productName}</td>
																		<td className="text-center">{item.productBarcode}</td>
																		<td className="text-center">{item.price}</td>
																		<td className="text-center">{item.fromPosBalance}</td>
																		<td>
																			<div className="table-actions">
																				<div className="table-action-button table-action-primary-button" onClick={() => addToList(index)}>
																					<i className="uil uil-plus"></i>
																				</div>
																			</div>
																		</td>
																	</tr>
																))
																}
															</tbody>
														</table>
													</div>
												</span>
											</div>
										</span>
									}

									{(searchData.length === 0 && searchDataExist === 1) &&
										<span className="dropdown-search-menu">
											<div className="dropdown-menu-list d-flex justify-content-between p-2">
												<span className="dropdown-menu-result fz20">{t('PRODUCT_NOT_FOUND')}</span>
											</div>
										</span>
									}
									{(searchData.length === 0 && searchDataExist === 2) &&
										<span className="dropdown-search-menu">
											<div className="dropdown-menu-list d-flex justify-content-between p-2">
												<span className="dropdown-menu-result fz20">{t('PRODUCT_ADDED')}</span>
											</div>
										</span>
									}
								</div>
							</div>
							<div className="col-md-6 d-flex justify-content-end">
								<div className="flex-column">
									<h5 className="d-flex justify-content-between"><strong className="me-2">{t('TOTAL')}:</strong> {formatMoney(data.totalAmount)}</h5>
								</div>
							</div>
						</div>

						<div className="btn-wrapper">
							<Link className="btn btn-outline-danger btn-wide me-2" to="/transfer">
								{t('CANCEL')}
							</Link>
							{!data.send &&
								<button type="button" className="btn btn-success btn-rounded btn-wide me-2"
									disabled={!data.productList.length > 0}
									onClick={() => setModalConfirm(true)}>{t('COMPLETE')}</button>
							}
							{!data.send &&
								<button type="button" className="btn btn-outline-primary btn-wide"
									disabled={!data.productList.length > 0}
									onClick={() => saveComplete(false)}>{t('SAVE_TO_DRAFT')}</button>
							}
						</div>
					</div>
				</div>
			</form>

			<Modal show={modalConfirm} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('CONFIRM')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-outline-danger w-100 me-2" onClick={() => setModalConfirm(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-outline-primary w-100" onClick={() => saveComplete(true)}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableTransfer'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}

			<ConfirmModal modalConfirm={modalDeleteConfirm.show} setModalConfirm={hideModalDeleteConfirm} confrim={deleteProduct}
				description="ARE_YOU_SURE_YOU_WANT_DELETE" />
		</>
	)
}

export default CreateUpdate

